import Sortable from '@stimulus-components/sortable';
import { patch } from '@rails/request.js';

export default class extends Sortable {
  async onUpdate(event) {
    const positions = this.sortable.toArray();
    const data = new FormData();

    positions.forEach((id, index) => {
      data.append(`positions[${id}]`, index + 1);
    });
    const extraAttributes = this.element.dataset.extraAttributes;
    if (extraAttributes) {
      extraAttributes.split(',').forEach((attribute) => {
        data.append(attribute, this.element.dataset[attribute]);
      });
    }

    const url = this.element.dataset.bulkUpdateUrl;
    return await patch(url, { body: data, responseKind: this.responseKindValue });
  }
}
