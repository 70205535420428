import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["globalSubmitPanel", "localSaveButton", "nextButton"];

  connect() {
    this.element.dataset.action +=
      ` form-change-monitor:formDirtied->global-save-button#showGlobalSubmitPanel
        form-change-monitor:connected@window->global-save-button#attachSubmitButtonListeners
      `;

    this.attachSubmitButtonListeners();

    addEventListener("turbo:before-stream-render", (event) => {
      const fallbackToDefaultActions = event.detail.render;
      
      event.detail.render = (streamElement) => {
        if (
          ["update", "replace"].includes(streamElement.action) &&
          (
            /section_status_.*/.test(streamElement.target) ||
            /nav_with_status_.*/.test(streamElement.target)
          )
        ) {
          this.attachSubmitButtonListeners();
        }
        fallbackToDefaultActions(streamElement);
      };
    });
  }

  attachSubmitButtonListeners() {
    this.localSaveButtonTargets.forEach((submitButton) => {
      // console.log("Register local button: ", submitButton);
      // let the local save button know that we took it over:
      submitButton.dataset.overridden = "true";
      submitButton.classList.add("hidden");
    });
  }

  showGlobalSubmitPanel(event) {
    if (event.detail.isModified) {
      this.globalSubmitPanelTarget.classList.remove("hidden");
      if (this.hasNextButtonTarget) {
        this.nextButtonTarget.classList.add("hidden");
      }
    } else {
      // Check if any form is still dirty
      const anyDirty = Array.from(
        document.querySelectorAll(
          '[data-form-change-monitor-target="submitButton"]'
        )
      ).some((button) => button.dataset.shouldSave === "true");

      if (!anyDirty) {
        this.globalSubmitPanelTarget.classList.add("hidden");
        if (this.hasNextButtonTarget) {
          this.nextButtonTarget.classList.remove("hidden");
        }
      }
    }
  }

  submitAllLocalForms() {
    const chains = []
    const chainedButtons = {}

    this.localSaveButtonTargets.forEach((submitButton) => {
      const shouldSave = submitButton.dataset.shouldSave === "true";
      const chain = submitButton.dataset.chain
      if (chain) {
        if (!chainedButtons[chain]) chainedButtons[chain] = [];
        if (shouldSave && !chains.includes(chain)) chains.push(chain);
        chainedButtons[chain].push(submitButton);
        return
      }

      if (shouldSave) {
        submitButton.click();
      }
    });

    this.processChainedButtons(chains, chainedButtons)
    this.globalSubmitPanelTarget.classList.add("hidden");
  }

  // These are buttons that have to wait for one another to prevent conflicting UI updates
  processChainedButtons(chains, chainedButtons) {
    chains.forEach((chain) => {
      const buttons = chainedButtons[chain]
      const listener = window.addEventListener('form-change-monitor:connected', () => {
        const button = buttons.shift()
        if (button) button.click();
        if (buttons.length === 0) {
          window.removeEventListener('form-change-monitor:connected', listener)
        }
      })

      const firstButton = buttons.shift()
      if (firstButton) firstButton.click();
    })
  }
}
