import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalContainer"];

  connect() {
    document.addEventListener(
      "modalclosed",
      this.hideModalContainer.bind(this)
    );
  }

  disconnect() {
    document.addEventListener(
      "modalclosed",
      this.hideModalContainer.bind(this)
    );
  }

  hideModalContainer() {
    this.modalContainerTarget.classList.add("hidden");
  }
  ShowModal() {
    this.modalContainerTarget.classList.remove("hidden");
  }
}
