import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'closedContent', 'openContent'];
  static values = { open: Boolean };

  connect() {
    this._updateVisibility();
  }

  toggle() {
    this.openValue = !this.openValue;
    this._updateVisibility();
  }

  _updateVisibility() {
    this.closedContentTarget.classList.toggle('hidden', this.openValue);
    this.openContentTarget.classList.toggle('hidden', !this.openValue);
  }
}
