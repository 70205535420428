import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    additionalHeight: Number,
  };

  initialize() {
    // https://github.com/hotwired/turbo-rails/issues/416#issuecomment-1715385853
    Chartkick.config.autoDestroy = false;

    if (!this.hasTurboLoadListener) {
      document.addEventListener(
        "turbo:render",
        this.handleTurboRender.bind(this)
      );
      this.hasTurboLoadListener = true;
    }
  }

  connect() {
    this.initializeCharts();
    this.debouncedLineChartDataAdder = this.debounce(() => {
      this.addLineChartData();
    });
    window.addEventListener("resize", this.debouncedLineChartDataAdder);
  }

  disconnect() {
    window.removeEventListener("resize", this.debouncedLineChartDataAdder);
  }

  handleTurboRender() {
    this.initializeCharts();
    Chartkick.eachChart((chart) => {
      chart.redraw();
    });
  }

  initializeCharts() {
    setTimeout(() => {
      this.addLineChartData();
    }, 500);
  }

  addLineChartData() {
    for (const chartName of Object.keys(Chartkick.charts)) {
      const chart = Chartkick.charts[chartName];

      if (!this._checkChartType(chartName, "chart-1")) {
        continue;
      }

      const datasets = chart.data;
      const chartValues = {};
      let maxValue = 0;
      datasets.forEach((el) => {
        const currData = el.data.slice(-1)[0][1];
        chartValues[el.name] = currData;
        if (currData > maxValue) maxValue = currData;
      });

      const lineChart = document.querySelector("#lineChart > div");

      datasets.forEach((dataset, index) => {
        const datasetMeta = chart.chart.getDatasetMeta(index);
        const lastPoint = datasetMeta.data[datasetMeta.data.length - 1];
        const { h: hue } = this.rgbaToHsl(lastPoint?.options.backgroundColor);

        if (hue == undefined) {
          return;
        }
        const existingDescription = this.element.querySelectorAll(
          `[data-name='${dataset.name}']`
        );
        const dataDesc = this._createDataDescription({
          value: chartValues[dataset.name],
          name: dataset.name,
          hue,
          maxValue,
        });
        const endPoint = this._createEndPoint({ hue, name: dataset.name });
        if (chartName === "chart-1") {
          if (existingDescription.length > 0)
            existingDescription.forEach((el) => el.remove());
          lineChart.insertAdjacentElement("beforeend", endPoint);
          lineChart.insertAdjacentElement("beforeend", dataDesc);
        }
      });

      const timeRangeDesc = this._createTimeRangeDescription({ datasets });
      lineChart.insertAdjacentElement("beforeend", timeRangeDesc);

      const keys = Object.keys(chartValues);
      if (keys.length === 2) {
        const differenceDesc = this._createDifferenceDescription({
          value: chartValues[keys[0]] - chartValues[keys[1]],
        });

        if (
          chartName === "chart-1" &&
          !document.querySelector("#difference-description")
        ) {
          lineChart.insertAdjacentElement("beforeend", differenceDesc);
        }
      }

      setTimeout(() => {
        const currChart = Chartkick.charts["chart-1"];
        if (!currChart) {
          return;
        }

        const currDatasets = currChart.data;
        const currBottoms = {};
        const currLefts = {};

        currDatasets.forEach((el, index) => {
          const metaData = currChart.chart.getDatasetMeta(index).data;
          const point = metaData[metaData.length - 1].getCenterPoint();
          const domY = point?.y;
          const domX = point.x - 4;

          currBottoms[el.name] = domY + this.additionalHeightValue;
          currLefts[el.name] = domX;
        });

        const dataDescs = document.querySelectorAll(`#data-desc`);
        dataDescs.forEach((el) => {
          const name = el.dataset.name;
          const endpoint = document.querySelector(
            `#end-point[data-name="${name}"]`
          );

          el.style.top = `${currBottoms[name] < 0 ? 0 : currBottoms[name] - 74}px`;
          el.style.left = `${currLefts[name] < 0 ? 0 : currLefts[name] + 20}px`;
          if (endpoint) {
            endpoint.classList.remove("hidden");
            endpoint.style.top = `${currBottoms[name] < 0 ? 0 : currBottoms[name] - 64}px`;
            endpoint.style.left = `${currLefts[name] < 0 ? 0 : currLefts[name]}px`;
          }

          // Remove unnecessary points
          Array.from(
            document.querySelectorAll(`#end-point[data-name="${name}"]`)
          )
            .filter((el) => !el.style.top)
            .forEach((el) => el.remove());

          if (currBottoms[name] < 55 && currBottoms[name] > 5) {
            const diffDesc = document.querySelector("#difference-description");
            diffDesc.classList.remove("bottom-8");
            diffDesc.classList.add("-bottom-4");
          }
        });
      }, 500);
    }
  }

  _checkChartType(chartName, type) {
    return chartName.includes(type);
  }

  _createEndPoint({ name, hue, id = "end-point" }) {
    const endPoint = document.createElement("span");
    endPoint.className =
      "min-w-2 size-2 rounded-full transition-all absolute hidden";
    endPoint.style.backgroundColor = `hsl(${hue}, 58%, 55%)`;
    endPoint.id = id;
    endPoint.dataset.name = name;
    return endPoint;
  }

  _createDataDescription({ value, name, hue, id = "data-desc", maxValue = 0 }) {
    const dataDesc = document.createElement("span");
    const widthPercentage = maxValue === 0 ? 1 : value / maxValue;
    dataDesc.className = `px-2.5 py-1 text-black  w-fit font-medium text-[14px] leading-[20px] rounded-md transition-all ${value < maxValue ? "" : "absolute   -right-47"}`;
    dataDesc.innerText = `${new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value)}`;

    dataDesc.style.minWidth = `${widthPercentage * 193}px`;
    dataDesc.style.top = `0px`;
    dataDesc.style.backgroundColor = `hsl(${hue}, 53%, 79%, 0.71)`;

    if (value >= maxValue) {
      dataDesc.id = id;
      dataDesc.dataset.name = name;
      return dataDesc;
    }

    const wrapper = document.createElement("div");
    wrapper.className =
      "min-w-[193px] absolute transition-all -right-47 flex justify-start bg-blue-400 rounded-md overflow-hidden";
    wrapper.style.backgroundColor = `hsl(${hue}, 73%, 93%)`;
    wrapper.insertAdjacentElement("afterbegin", dataDesc);
    dataDesc.style.top = `0px`;
    // wrapper.style.top = `0px`;
    wrapper.dataset.name = name;
    wrapper.id = id;

    return wrapper;
  }

  _createTimeRangeDescription({ datasets }) {
    const currValue = document.querySelector("#time-range-description");
    if (currValue) currValue.parentElement.removeChild(currValue);
    const timeRange =
      datasets[0].data[datasets[0].data.length - 1][0] -
      datasets[0].data[0][0] +
      1;
    const timeRangeDesc = document.createElement("span");
    timeRangeDesc.className =
      "absolute -bottom-4 right-5 text-[#9CA3AF] text-[12px] leading-[20px]";
    timeRangeDesc.innerText = `Laufzeit ${timeRange} Jahre`;
    timeRangeDesc.id = "time-range-description";
    return timeRangeDesc;
  }

  _createDifferenceDescription({ value, id = "difference-description" }) {
    const differenceDesc = document.createElement("span");
    differenceDesc.className =
      "absolute bottom-8 -right-52 w-fit text-[#9CA3AF] text-[12px] leading-[20px] min-w-[193px]";
    differenceDesc.innerText = `Differenz ${new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value)}`;
    differenceDesc.id = id;

    return differenceDesc;
  }

  rgbaToHsl(rgba) {
    if (!rgba || typeof rgba !== "string") return {};

    const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/;
    const match = rgba.match(rgbaRegex);
    if (!match) return {};

    const [, r, g, b, a] = match.map(parseFloat);

    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);

    let l = (min + max) / 2;

    let s = 0;
    if (min !== max) {
      s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
    }

    let h = 0;
    if (min !== max) {
      if (max === r) {
        h = (g - b) / (max - min);
      } else if (max === g) {
        h = 2 + (b - r) / (max - min);
      } else {
        h = 4 + (r - g) / (max - min);
      }
    }
    h *= 60;
    if (h < 0) {
      h += 360;
    }

    h = Math.round(h * 100) / 100;
    s = Math.round(s * 100) / 100;
    l = Math.round(l * 100) / 100;

    return { h, s, l };
  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
}
