import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
    this.updateAddButtonState();
  }

  updateAddButtonState() {
    let addButton = document.getElementsByClassName('add_share_holder');
    let removeButton = document.getElementsByClassName('delete_share_holder');
    if (addButton) {
      if (removeButton.length >= 3) {
        addButton[0].classList.add('hidden');
      } else {
        addButton[0].classList.remove('hidden');
      }
    }
  }
}
