import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "menu",
    "backdrop",
    "backdropContent",
    "button",
    "userOptions",
    "userOptionsList",
  ];
  static values = { userOptionState: Boolean };

  connect() {
    this.userOptionsStateValue = false;
    const currSelected = document.querySelector(
      ".border-l-\\[6px\\].border-\\[\\#3EFFA8\\]"
    );
    if (currSelected) {
      currSelected.parentElement.scrollIntoView();
    }
  }

  toggleUserOptions() {
    this.userOptionStateValue = !this.userOptionStateValue;
    this.userOptionsListTarget.classList.toggle(
      "hidden",
      !this.userOptionStateValue
    );
    this.userOptionsListTarget.classList.toggle(
      "flex",
      this.userOptionStateValue
    );
  }

  toggleMenu() {
    if (this.menuTarget.classList.contains("translate-x-0")) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  showMenu() {
    this.menuTarget.classList.add("translate-x-0");
    this.backdropTarget.classList.add("opacity-100");
    this.backdropTarget.classList.remove("hidden");
    this.backdropContentTarget.classList.remove("hidden");
    this.buttonTarget.classList.add("opacity-100");
  }

  hideMenu() {
    this.menuTarget.classList.remove("translate-x-0");
    this.backdropTarget.classList.remove("opacity-100");
    this.backdropTarget.classList.add("hidden");
    this.backdropContentTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("opacity-100");
  }
}
