import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static values = {
    options: Object,
    isturbo: Boolean,
  };

  // connect() { console.log('Loaded modal controller') }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    if (!this.optionsValue.keep_content) {
      this.element.parentElement.removeAttribute("src");
      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent click
      if (this.isturboValue) this.modalTarget.remove();

      document.dispatchEvent(
        new CustomEvent("modalclosed", {
          bubbles: true,
        })
      );
    }
  }

  // hide modal on successful form submission
  // This breaks things when having a form inside the modal where the modal shoulday
  // See the component, I added a flag close_on_success
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal();
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (
      e &&
      this.modalTarget.contains(e.target) &&
      this.modalTarget !== e.target
    ) {
      return;
    }
    this.hideModal();
  }

  closeModal(e) {
    this.modalTarget.classList.add("hidden");

    this.hideModal();
  }

  hideWrapper(ev) {
    if (ev.srcElement.id === "modalWrapper") this.hideModal();
  }

  showModal(ev) {
    const toggledModalId =
      ev.target.tagName.toLowerCase() === "span"
        ? ev.target.closest("button").dataset.modalToggleId
        : ev.target.dataset.modalToggleId;
    const toggledModal = document.querySelector(
      `[data-modal-id="${toggledModalId}"]`
    );

    toggledModal?.classList.remove("hidden");
  }
}
