import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "firstProductTypeField",
    "firstInsuranceCompanyField",
    "firstProductField",
    "secondInsuranceCompanyField",
    "secondProductField",
    "existingContract",
    "existingContractFields",
    "firstParameterList",
    "secondParameterList",
  ];

  static values = {
    firstProductId: Number,
    secondProductId: Number,
  };

  connect() {
    this.getFirstProductCompanies();
    this.getSecondProductCompanies();
    if (this.existingContractTarget.checked) {
      this.showExistingContractFields();
    }
    this.updateValueOnLoad();
    this.getNettoCompanies();
  }

  async getNettoCompanies() {
    const companies = await fetch(
      `/agents/comparison_calculators/insurance_companies_by_type?company_type=net`
    );
    const res = await companies.json();
  }

  getFirstProductCompanies(event) {
    try {
      let productType;
      if (event && event.srcElement.tagName === "DIV")
        productType = event.target.querySelector("input[type='radio']").value;
      else if (event && event.srcElement.tagName === "LABEL")
        productType = event.target.parentElement.querySelector(
          "input[type='radio']"
        ).value;
      else {
        for (let type of this.firstProductTypeFieldTargets) {
          const rad = type.querySelector("input[type='radio']");
          if (rad && rad.checked) {
            productType = rad.value;
            break;
          }
        }
      }
      if (productType) {
        fetch(
          `/agents/comparison_calculators/company_data?product_type=${productType}`
        )
          .then((response) => response.json())
          .then((data) => {
            this.updateSelectOptions(
              this.firstInsuranceCompanyFieldTarget,
              data
            );
            this.clearSelectOptions(this.firstProductFieldTarget);
            this._clearList(this.firstParameterListTarget);
          });
      } else {
        this.clearSelectOptions(this.firstInsuranceCompanyFieldTarget);
        this.clearSelectOptions(this.firstProductFieldTarget);
        this._clearList(this.firstParameterListTarget);
      }
      this._closeList(this.firstParameterListTarget.id);
      // this.firstShowParameterTarget.setAttribute("href", "#");
      // this.addDisabledButtonClasses(this.firstShowParameterTarget);
      // this.clearSelectOptions(this.firstProductFieldTarget);
    } catch (err) {}
  }

  getFirstProducts(event) {
    const insuranceCompanyId = event.target.value;

    if (insuranceCompanyId) {
      fetch(
        `/agents/comparison_calculators/product_data?insurance_company_id=${insuranceCompanyId}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.updateSelectOptions(this.firstProductFieldTarget, data);
        });
    } else {
      this.clearSelectOptions(this.firstProductFieldTarget);
    }
    this._clearList(this.firstParameterListTarget);
    this._closeList(this.firstParameterListTarget.id);

    // this.firstShowParameterTarget.setAttribute("href", "#");
    // this.addDisabledButtonClasses(this.firstShowParameterTarget);
  }

  async updateValueOnLoad() {
    const firstProductId = this.firstProductIdValue;
    const secondProductId = this.secondProductIdValue;

    if (firstProductId !== 0 && secondProductId !== 0) {
      this.fetchAndRender(
        `/agents/policy_applications/set_selected_product?first_product_id=${firstProductId}&second_product_id=${secondProductId}`
      );
    }
  }

  async fetchAndRender(url) {
    const response = await fetch(url);
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  async getFirstProductParameters(event) {
    const productId = event.target.value;
    this._clearList(this.firstParameterListTarget);
    this._closeList(this.firstParameterListTarget.id);

    if (productId) {
      const res = await fetch(
        `/agents/policy_applications/product_data?insurance_product_id=${productId}`
      );
      const items = await res.json();

      this._setListItems(
        this.firstParameterListTarget,
        this._formatParameterData(items)
      );
    }
  }

  _formatParameterData(received) {
    const res = {};
    for (let data of received) {
      for (let subData of data["data"]) {
        switch (subData["heading"]) {
          case "closing_cost":
            res["closing_cost"] = subData;
            continue;
          case "additional_payment_cost":
            res["additional_payment_cost"] = subData;
            continue;
          case "investment_cost":
            res["investment_cost"] = subData;
            continue;
          case "credit_insurance_cost":
            res["credit_insurance_cost"] = subData;
            continue;
          case "per_unit_cost":
            res["per_unit_cost"] = subData;
            continue;
          case "administrative_cost":
            res["administrative_cost"] = subData;
            continue;
        }
      }
    }

    return res;
  }

  _setListItems(list, items) {
    for (let key in items) {
      const item = items[key];
      const listItem = document.createElement("li");
      listItem.classList.add(
        "flex",
        "justify-between",
        "text-text-30",
        "font-medium"
      );
      const itemName = document.createElement("span");
      itemName.innerText = this._capitalize(item.heading.replaceAll("_", " "));
      const itemValue = document.createElement("span");
      itemValue.innerText = `${item.value} ${item.symbol}`;
      listItem.append(itemName);
      listItem.append(itemValue);
      list.append(listItem);
    }
  }

  getSecondProductCompanies() {
    fetch(`/agents/comparison_calculators/company_data?product_type=net`)
      .then((response) => response.json())
      .then((data) => {
        this.updateSelectOptions(this.secondInsuranceCompanyFieldTarget, data);
      });
  }

  getSecondProducts(event) {
    const insuranceCompanyId = event.target.value;

    if (insuranceCompanyId) {
      fetch(
        `/agents/comparison_calculators/product_data?insurance_company_id=${insuranceCompanyId}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.updateSelectOptions(this.secondProductFieldTarget, data);
        });
    } else {
      this.clearSelectOptions(this.secondProductFieldTarget);
    }
    this._clearList(this.secondParameterListTarget);
    this._closeList(this.secondParameterListTarget.id);
  }
  async getSecondProductParameters(event) {
    const productId = event.target.value;

    this._clearList(this.secondParameterListTarget);
    this._closeList(this.secondParameterListTarget.id);
    if (productId) {
      const res = await fetch(
        `/agents/policy_applications/product_data?insurance_product_id=${productId}`
      );
      const items = await res.json();
      this._setListItems(
        this.secondParameterListTarget,
        this._formatParameterData(items)
      );
    }
  }
  showExistingContractFields() {
    try {
      this.existingContractFieldsTarget.classList.toggle(
        "hidden",
        !this.existingContractTarget.checked
      );
      this.existingContractFieldsTarget.classList.toggle(
        "flex",
        this.existingContractTarget.checked
      );
    } catch (err) {}
  }

  updateSelectOptions(selectElement, data) {
    // Clear current option
    this.clearSelectOptions(selectElement);

    // Add new options based on the received data
    data.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option.value;
      optionElement.text = option.text;
      selectElement.add(optionElement);
    });
  }
  clearSelectOptions(selectElement) {
    // Clear all options in the select element
    while (selectElement.options.length > 1) {
      selectElement.options[1] = null;
    }
  }

  _closeList(listIdentifier) {
    if (!listIdentifier) return;
    this.element.dispatchEvent(
      new CustomEvent("close-list", {
        bubbles: true,
        detail: { id: listIdentifier },
      })
    );
  }

  _clearList(listParent) {
    const items = listParent.querySelectorAll("li");
    const toggler = listParent.parentElement.querySelector("[data-action]");
    if (items && items.length > 0) {
      items.forEach((item) => {
        item.remove();
      });
    }
    if (toggler) toggler.click();
  }
  _capitalize(text) {
    const splitText = text.split("");
    splitText[0] = String.fromCharCode(splitText[0].charCodeAt() - 32);
    return splitText.join("");
  }
}
