import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["eventBus", "displayedToggle", "content", "checkbox", "labelContainer"];

  connect() {
    this.toggleStyles();
    this.toggleContent();
    document.addEventListener("toggleCheckbox", (ev) => {
      if (ev.detail.elementId === this.displayedToggleTarget.id) this.toggle();
    });
    document.addEventListener("toggleDisabled", (ev) => {
      if (ev.detail.elementId === this.displayedToggleTarget.id)
        this.toggleDisabled();
    });
  }

  toggleDisabled() {
    const isDisabled = this.checkboxTarget.getAttribute("disabled");
    const label = this.labelContainerTarget.querySelector("label");
    label.classList.toggle("text-text-main", isDisabled);
    label.classList.toggle("text-[#8784a9]", !isDisabled);

    this.displayedToggleTarget.classList.toggle("bg-gray-200", isDisabled);
    this.displayedToggleTarget.classList.toggle("bg-gray-100", !isDisabled);

    if (isDisabled) this.checkboxTarget.removeAttribute("disabled");
    else this.checkboxTarget.setAttribute("disabled", !isDisabled);
  }

  toggle(_ev) {
    if (this.checkboxTarget.disabled) return;

    this.checkboxTarget.checked = !this.checkboxTarget.checked;

    // https://fullstackheroes.com/tutorials/stimulus/create-custom-events/
    // TODO: fix. bad name for a global event...
    if (this.hasEventBusTarget) {
      const toggleEvent = new CustomEvent("change", {
        detail: { checked: this.checkboxTarget.checked },
      })
      this.eventBusTarget.dispatchEvent(toggleEvent);
    } else {
      this.checkboxTarget.dispatchEvent(new Event("change"), {
        details: { checked: this.checkboxTarget.checked },
      });
    }
    this.toggleStyles();
    this.toggleContent();
  }

  toggleStyles() {
    const isVisible = this.checkboxTarget.checked;
    const displayedChild = this.displayedToggleTarget.querySelector("span");
    if (this.checkboxTarget.disabled) {
      this.displayedToggleTarget.classList.remove("bg-gray-200");
      this.displayedToggleTarget.classList.add("bg-[#B3BECA]");
    } else {
      this.displayedToggleTarget.classList.toggle(
        "bg-brand-primary",
        isVisible
      );
      this.displayedToggleTarget.classList.toggle("bg-gray-200", !isVisible);
    }
    displayedChild.classList.toggle("translate-x-5", isVisible);
    displayedChild.classList.toggle("translate-x-0", !isVisible);
  }

  toggleContent() {
    try {
      const isVisible = this.checkboxTarget.checked;

      // Quick fix so we don't screw it up
      const disableBlanking = this.checkboxTarget.dataset.disableblanking;

      this.contentTarget.classList.toggle("flex", isVisible);
      this.contentTarget.classList.toggle("hidden", !isVisible);

      if (!isVisible && !disableBlanking) {
        this.clearInputs();
        this.resetSelects();
        this.clearCheckboxes();
        this.clearRadios();
        this.triggerChildToggle();
      }
    } catch (error) {
      return;
    }
  }

  clearInputs() {
    const inputs = this.contentTarget.querySelectorAll(
      'input[type="text"], input[type="number"], input[type="date"]'
    );
    inputs.forEach((input) => (input.value = ""));
  }

  resetSelects() {
    const selects = this.contentTarget.querySelectorAll("select");
    selects.forEach((select) => (select.selectedIndex = 0));
  }

  triggerChildToggle() {
    const toggleTargets = this.contentTarget.querySelectorAll(
      '[data-toggle-target="displayedToggle"]'
    );
    toggleTargets.forEach((element) => {
      const nativeCheck = element.parentElement.querySelector(
        "input[type='checkbox']"
      );
      if (nativeCheck.checked) {
        element.click();
      }
    });
  }

  clearCheckboxes() {
    const checkboxes = this.contentTarget.querySelectorAll(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
  }

  clearRadios() {
    const radios = this.contentTarget.querySelectorAll('input[type="radio"]');
    radios.forEach((radio) => (radio.checked = false));
  }
}
