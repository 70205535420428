import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio"];
  checkedClasses = ["border-2", "border-brand-primary"];
  uncheckedClasses = ["border", "border-[#D1D5DB]"];

  connect() {
  }

  toggle(event) {
    const selectedElement = event.srcElement;
    this.radioTargets.forEach((target) => {
      const nativeRadio = target.querySelector("input[type=radio]");
      if (
        target === selectedElement ||
        target === selectedElement.parentElement
      ) {
        target.classList.remove(...this.uncheckedClasses);
        target.classList.add(...this.checkedClasses);
        nativeRadio.checked = true;
      } else {
        target.classList.remove(...this.checkedClasses);
        target.classList.add(...this.uncheckedClasses);
        nativeRadio.checked = false;
      }
    });
  }
}
