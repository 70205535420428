// app/javascript/controllers/auto_select_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Optional: Log to ensure the controller is connected properly
    // console.log("AutoSelectController connected");
  }

  selectAll(event) {
    event.target.select();
  }
}
