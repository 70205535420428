import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "filePreview", "removeButton"];
  static values = { showIcon: Boolean }

  connect() {
    this.fileInputTarget.addEventListener("change", this.handleFileChange.bind(this));
    this.removeButtonTarget.addEventListener("click", this.removeImage.bind(this));
  }

  handleFileChange(event) {
    const file = event.target.files[0];

    if (file) {
      if(file.type == 'application/pdf'){
        this.filePreviewTarget.innerHTML = file.name;
      }else{
        const reader = new FileReader();
        reader.onload = (e) => {
          this.filePreviewTarget.innerHTML = `
            <img src="${e.target.result}" class="h-16 w-16 mx-auto">
          `;
        };
        reader.readAsDataURL(file);
      }

      this.removeButtonTarget.hidden = false
    }
  }

  removeImage() {
    this.fileInputTarget.value = null;
    if (this.showIconValue) {
      this.filePreviewTarget.innerHTML = `
      <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
      </svg>
      `;
    } else {
      this.filePreviewTarget.innerHTML = "";
    }
    this.removeButtonTarget.hidden = true
  }
}
