import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.addColumnChartData();
    }, 500);
  }

  addColumnChartData() {
    for (const chartName of Object.keys(Chartkick.charts)) {
      const chart = Chartkick.charts[chartName];
      if (!this._checkChartType(chartName, "column")) continue;

      const datasets = chart.data;
      const chartValues = {};
      let maxValue = 0;
      datasets.forEach((el) => {
        const currData = el.data.slice(-1)[0][1];
        chartValues[el.name] = currData;
        if (currData > maxValue) maxValue = currData;
      });
      const currentData = datasets.map(product => product.data.map(entry => entry[1]));

      const datasetMeta = chart.chart.getDatasetMeta(0);
      const positionData = datasetMeta.data.map(barElement => barElement.x);

      const columnChart = document.querySelector('#columnChart');
      for (let i = 0; i < currentData.length; i++) {
        const container = this._createContainer(columnChart, i + 1);
        const labelHeading = this._createLabelHeading({ position: positionData[0], idx: i });
        const labelsData = this._createLabelsData({
          values: currentData[i],
          positions: positionData,
          idx: i
        });

        container.appendChild(labelHeading);
        labelsData.forEach(labelElement => container.appendChild(labelElement));
        columnChart.insertAdjacentElement("beforeend", container)
      }
      
      const keys = Object.keys(chartValues);
      if (keys.length === 2) {
        const differenceDesc = this._createDifferenceDescription({
          value: chartValues[keys[0]] - chartValues[keys[1]],
        });

        if (
          chartName === "column-chart" &&
          !document.querySelector("#difference-description1")
        ) {
          columnChart.insertAdjacentElement("afterbegin", differenceDesc);
        }
      }
    }
  }

  _createContainer(columnChart, i) {
    const previousContainer = document.querySelector(`#columnChartProduct${i}`);
        if (previousContainer) columnChart.removeChild(previousContainer);
    const container = document.createElement('div');
    container.className = 'mt-12 relative flex items-center';
    container.id = `columnChartProduct${i}`

    return container;
  }

  _createLabelHeading({ position, idx }) {
    const span = document.createElement('span');
    const textColors = ['text-[#3EFFA8]', 'text-[#4F46E5]'];
    span.className = `${textColors[idx]} font-bold text-sm leading-5 absolute max-w-[120px]`;
    span.style.left = `${position - 160}px`;
    let names = ['Nettoprodukt', 'Bruttoprodukt'];

    span.innerText = names[idx];
    return span;
  }

  _createLabelsData({ values, positions, idx }) {
    return values.map((value, idx) => {
      const span = document.createElement('span');
      span.className = `absolute text-[#4B5563] text-xs leading-5`;
      span.style.left = `${positions[idx] - 15}px`;
      span.innerText = `${new Intl.NumberFormat("de-DE", {
        style: "currency",
         currency: "EUR"
        }).format(value)}`;
      return span;
    });
  }

  _createDifferenceDescription({ value, id = "difference-description1" }) {
    const differenceDesc = document.createElement("span");
    differenceDesc.className = 'absolute top-0 right-0 text-[#9CA3AF] text-[12px] leading-[20px] min-w-[193px]';
    differenceDesc.innerText = `Differenz ${new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value)}`;
    differenceDesc.id = id;

    return differenceDesc;
  }

  _checkChartType(chartName, type) {
    return chartName.includes(type);
  }
}
