import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nav", "link", "content"];

  connect() {
    this.boundHighlightCurrentSection = this.highlightCurrentSection.bind(this);
    this.contentTarget.addEventListener(
      "scroll",
      this.boundHighlightCurrentSection
    );
  }

  disconnect() {
    this.contentTarget.removeEventListener(
      "scroll",
      this.boundHighlightCurrentSection
    );
  }

  navigate(event) {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    targetElement.scrollIntoView({ behavior: "smooth" });
  }

  highlightCurrentSection() {
    const sections = this.contentTarget.querySelectorAll("section");
    let index = sections.length;

    // Check if we are at the bottom of the scrollable container
    if (
      this.contentTarget.scrollTop + this.contentTarget.clientHeight >=
      this.contentTarget.scrollHeight
    ) {
      index = sections.length - 1; // Select the last section
    } else {
      // Otherwise, find the current section in view
      while (
        --index &&
        this.contentTarget.scrollTop + 50 <
          sections[index].offsetTop - this.contentTarget.offsetTop
      ) {}
    }

    this.highlightSection(index);
  }

  highlightSection(index) {
    this.linkTargets.forEach((link) => {
      link.classList.remove("bg-gray-200");
    });

    if (this.linkTargets[index]) {
      this.linkTargets[index].classList.add("bg-gray-200");
    }
  }
}
