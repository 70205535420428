import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "insuranceCompanyFieldd",
    "insuranceProductFieldd",
  ];
//   static values = {
//     options: String
//   }

  connect() {
  }

  // reset() {
  //   const companySelect = document.getElementById("customer_existing_policy_attributes_insurance_company_id");
  //   const productSelect = document.getElementById("customer_existing_policy_attributes_policy_id");
  //   const startDateInput = document.getElementById("customer_existing_policy_attributes_start_date");
  //   const endDateInput = document.getElementById("customer_existing_policy_attributes_end_date");
  //   const monthlyPaymentInput = document.getElementById("customer_existing_policy_attributes_monthly_payment");
  //   const shortendContributionPeriodInput = document.getElementById("customer_existing_policy_attributes_shortend_contribution_period");
  //   const currentValueInput = document.getElementById("customer_existing_policy_attributes_current_value");


  //   this.resetSelectElement(companySelect);
  //   this.resetSelectElement(productSelect);
  //   this.resetInputElement(startDateInput);
  //   this.resetInputElement(endDateInput);
  //   this.resetInputElement(monthlyPaymentInput);
  //   this.resetInputElement(shortendContributionPeriodInput);
  //   this.resetInputElement(currentValueInput);
  // }

  reset() {
    const inputFields = [
      document.getElementById("customer_existing_policy_attributes_insurance_company_id"),
      document.getElementById("customer_existing_policy_attributes_policy_id"),
      document.getElementById("customer_existing_policy_attributes_start_date"),
      document.getElementById("customer_existing_policy_attributes_end_date"),
      document.getElementById("customer_existing_policy_attributes_monthly_payment"),
      document.getElementById("customer_existing_policy_attributes_shortend_contribution_period"),
      document.getElementById("customer_existing_policy_attributes_current_value")
    ];
  
    inputFields.forEach(field => this.resetElement(field));
  }
  
  resetElement(element) {
    if (element.tagName === "SELECT") {
      this.resetSelectElement(element);
    } else {
      this.resetInputElement(element);
    }
  }
  
  resetSelectElement(selectElement) {
    selectElement.selectedIndex = 0; 
  }

  resetInputElement(inputElement) {
    inputElement.value = "";
  }
}
