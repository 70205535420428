// app/javascript/controllers/auto_select_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "form", "button", "disabledButtonContent", "enabledButtonContent"]

  connect() {}

  submitForm() {
    if (this.formTarget.dataset.required && this.hasFieldTarget) {
      if (this.fieldTarget.value === "") return;
    }

    this.formTarget.requestSubmit()

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener('click', (event) => { event.preventDefault() })
      this.enabledButtonContentTarget.classList.add('hidden')
      this.disabledButtonContentTarget.classList.remove('hidden')
    }
  }
}
