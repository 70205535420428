import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["normalizedValue", "userInput"];
  static values = {old: String};

  connect() {
    this.setFormattedUserInputValue(this.normalizedValueTarget.value);
  }

  valueChange(_ev) {
    if (!this.validateInput(this.userInputTarget.value)) {
      this.userInputTarget.value = this.oldValue;
    }
    this.oldValue = this.userInputTarget.value;
    this.updateNormalizedInputValue();
  }

  checkEmptyAndUpdateNormalized() {
    const currValue = this.userInputTarget.value.trim(); // Trim spaces
    const [mainValue, decimalValue] = currValue.split(",");
    const correctedMainValue =
      !mainValue || mainValue.trim().length === 0 ? "00" : mainValue.trim();
    const correctedDecimalValue =
      !decimalValue || decimalValue.trim().length === 0
        ? ""
        : decimalValue.trim();

    this.userInputTarget.value = `${correctedMainValue}${correctedDecimalValue ? "," + correctedDecimalValue : ""}`;
    this.updateNormalizedInputValue();
  }

  updateNormalizedInputValue() {
    const value = this.userInputTarget.value
      .replace(",", ".")
      .replace(" ", "")
      .trim(); // Trim spaces
    const normalizedValue = parseFloat(value / 100).toFixed(4); // Limit to 4 decimal places
    this.normalizedValueTarget.value = isNaN(normalizedValue)
      ? 0
      : normalizedValue;
  }

  setFormattedUserInputValue(value) {
    const percentageValue = (parseFloat(value) * 100).toFixed(2); // Limit to 2 decimal places
    let formattedPercentageValue = "0,00";
    if (!isNaN(percentageValue)) {
      formattedPercentageValue = percentageValue.toString().replace(".", ",").trim();
    }
    this.userInputTarget.value = formattedPercentageValue;
    this.oldValue = formattedPercentageValue;
  }

  validateInput(value) {
    const regex = /^[0-9]*,?[0-9]*$/;
    return regex.test(value);
  }
}
