import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select']

  async export(e) {
    e.preventDefault()

    const selectedIds = this.getSelectedIds()
    const url = this.element.dataset.exportUrl
    const name = this.element.dataset.exportName || 'export.csv'
    const query = selectedIds.map(id => `ids[]=${id}`).join('&')

    const link = document.createElement('a')
    link.href = `${url}?${query}`
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  getSelectedIds() {
    return this.selectTargets.filter(select => select.checked).map(select => select.value)
  }
}
