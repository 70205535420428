import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon", "listContent"];
  static values = { open: Boolean };

  connect() {
    document.addEventListener("close-list", this._hideItemsExternal.bind(this));
    this._hideItems();
  }

  toggle() {
    const items = this.listContentTarget.querySelectorAll("li");
    if (!items || !(items.length > 0)) return;
    this.openValue = !this.openValue;
    if (this.openValue) {
      this._showItems();
    } else {
      this._hideItems();
    }
  }
  _showItems() {
    this.iconTarget.querySelector("path").setAttribute("d", "M5 15L12 8L19 15");
    this.listContentTarget.classList.add("flex");
    this.listContentTarget.classList.remove("hidden");
  }
  _hideItems() {
    this.openValue = false;
    this.iconTarget.querySelector("path").setAttribute("d", "M19 9L12 16L5 9");
    this.listContentTarget.classList.add("hidden");
    this.listContentTarget.classList.remove("flex");
  }
  _hideItemsExternal(event) {
    if (
      event &&
      event.detail &&
      event.detail.id &&
      this.listContentTarget.id === event.detail.id
    ) {
      this._hideItems();
    }
  }
}
