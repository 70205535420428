// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["email", "password"];

  connect() {
    console.log("Connected");
  }

  login(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const email = link.dataset.email;
    const password = link.dataset.password;
    const emailTarget = this.emailTarget;
    const passwordTarget = this.passwordTarget;

    // Set the values in the respective email and password fields
    emailTarget.value = email;
    passwordTarget.value = password;
  }
}
