import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["linkList", "intersectionSection", "linkedData"];

  static values = {
    intersectionSectionScrollable: Boolean,
    productId: Number,
    isScrolling: Boolean,
    showStatus: Object,
  };

  connect() {
    this.intersectionSectionScrollableValue = false;
    this.setTitleLinks();

    this.initIntersectionObserver();

    document.addEventListener(
      "toggleShowStatus",
      this.toggleShowStatus.bind(this)
    );

    const firstLinkItem = this.linkListTarget.querySelector("li");
    firstLinkItem.classList.add("text-text-main");

    // this.initScrollListener();
    this.updateValuesOnLoad();
    document.addEventListener("formStatusUpdate", (ev) => {
      this.linkListTarget.querySelectorAll("li").forEach((el) => {
        if (ev.detail.formId === el.dataset.linkFor) {
          const status = el.querySelector("span");
          const hasError = ev.detail.status === "error";
          status.classList.toggle(
            "hidden",
            !this.showStatusValue[ev.detail.formId]
          );
          status.classList.toggle("bg-red-500", hasError);
          status.classList.toggle("bg-green-400", !hasError);
        }
      });
    });
  }

  toggleShowStatus(ev) {
    const newObj = {};
    newObj[ev.detail.formId] = true;
    this.showStatusValue = { ...this.showStatusValue, ...newObj };

    if (ev) {
      document.dispatchEvent(new CustomEvent("formStatusUpdate", ev));
    }
  }

  disconnect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    document.removeEventListener(
      "toggleShowStatus",
      this.toggleShowStatus.bind(this)
    );
  }

  initIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: this.intersectionSectionTarget,
        rootMargin: "0px",
        threshold: 0.75,
      }
    );
  }

  initScrollListener() {
    if (!this.intersectionSectionScrollableValue) {
      this.intersectionSectionTarget.addEventListener("scroll", () => {
        this.intersectionSectionScrollableValue = true;
        this.linkedDataTargets.forEach((target) =>
          this.intersectionObserver.observe(target)
        );
      });
    }
  }

  handleIntersection(entries) {
    if (this.isScrollingValue) return;
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.intersectionSectionScrollableValue) {
        this.handleIntersectionVisibility(entry.target.id);
      }
    });
  }

  handleIntersectionVisibility(targetId) {
    this.linkListTarget.querySelectorAll("li").forEach((item) => {
      const isTarget = item.getAttribute("data-link-for") === targetId;
      item.classList.toggle("text-text-main", isTarget);
      // item.classList.toggle("bg-indigo-50", isTarget);
      if (isTarget) {
        location.href = location.href.split("#")[0] + `#${targetId}`;
      }
    });
  }

  setTitleLinks() {
    this._clearList(this.linkListTarget);
    this.linkedDataTargets.forEach((target) => this.createLinkItem(target));
  }

  createLinkItem(target) {
    const targetTitle = target.querySelector("h3");
    let displayName = targetTitle.dataset.sidebarDisplayName;
    displayName ||= targetTitle.innerText;
    const displayId = targetTitle.dataset.idValue || displayName;
    const targetId = this._generateValidId(displayId);

    target.id = targetId;

    const linkItem = document.createElement("li");
    linkItem.className =
      "py-2 px-3 text-sm font-medium text-brand-primary rounded-md flex justify-between items-center";

    linkItem.setAttribute("data-link-for", targetId);

    const link = document.createElement("a");
    link.href = `#${targetId}`;
    link.innerText = displayName;
    link.setAttribute(
      "data-action",
      "click->form-with-navigation#navigateToSection:prevent"
    );

    const status = document.createElement("span");
    status.className = "size-3 min-w-3 rounded-full hidden";

    linkItem.append(link);
    linkItem.append(status);
    this.linkListTarget.append(linkItem);
  }

  navigateToSection(ev) {
    this.isScrollingValue = true;
    const itemSelector = ev.srcElement.href.split("#")[1];
    const currItem = document.querySelector(`#${itemSelector}`);
    currItem.scrollIntoView({ behavior: "smooth" });
    history.pushState(null, null, ev.srcElement.href);
    this.updateLinkListActiveClass(ev.srcElement.href);
    setTimeout(() => {
      this.isScrollingValue = false;
    }, 750);
  }

  updateLinkListActiveClass(currentHref) {
    this.linkListTarget.querySelectorAll("li").forEach((target) => {
      const itemLink = target.querySelector("a").href;
      target.classList.toggle("text-text-main", itemLink === currentHref);
      // target.classList.toggle("bg-indigo-50", itemLink === currentHref);
    });
  }

  _clearList(listParent) {
    listParent.innerHTML = "";
  }

  _generateValidId(text) {
    return text
      .replace(/[^a-zA-Z0-9]+/g, "_")
      .replace(/^_+|_+$/g, "")
      .toLowerCase();
  }

  updateValuesOnLoad() {
    try {
      const insuranceCompanyId = this.insuranceCompanyFieldTarget.value;
      const productId = this.productIdValue;

      if (insuranceCompanyId && productId != 0) {
        this.fetchAndRender(
          `/agents/customers/set_selected_product?insurance_company_id=${insuranceCompanyId}&product_id=${productId}`
        );
      }
    } catch (err) {}
  }

  getInsuranceProducts(event) {
    const insuranceCompanyId = event.target.value;

    if (insuranceCompanyId) {
      this.fetchAndRender(
        `/agents/customers/products_data?insurance_company_id=${insuranceCompanyId}`
      );
    } else {
      this.clearSelectOptions(this.insuranceProductFieldTarget);
    }
  }

  async fetchAndRender(url) {
    const response = await fetch(url);
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }
}
