import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form', 'editButton', 'value', 'saveButton']

  makeEditable() {
    this.formTarget.classList.remove('hidden')
    this.editButtonTarget.classList.add('hidden')
    this.valueTarget.classList.add('hidden')
    this.saveButtonTarget.classList.remove('hidden')
  }

  onSave() {
    this.formTarget.requestSubmit()
  }

  toggleEdit(event) {
    if (!this.hasEditButtonTarget) return;
    const isEditable = event.detail.on

    if(isEditable) {
      this.editButtonTarget.classList.add('hidden')
    } else {
      this.editButtonTarget.classList.remove('hidden')
    }
  }
}
