import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static values = {
    options: Object,
    isCreatable: {
      type: Boolean,
      default: false
    },
    textOptions: Array
  }

  connect() {
    this.attach();
  }

  disconnect() {
    this.slimselect.destroy();
  }

  attach() {
    if (this.slimselect) this.slimselect.destroy();

    let events = {
      afterChange: () => {
        this.element.dispatchEvent(
          new CustomEvent('input', {
            bubbles: true,
          })
        )
      },
    }


    if (this.isCreatableValue) {
      let texts = this.textOptionsValue
      events = {
        ...events,
        addable: function (value) {
          if (texts.includes(value)) {
            return false;
          }

          return {
            text: value,
            value: value
          }
        }
      }
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      events: events,
      ...this.optionsValue
    });
  }
}
