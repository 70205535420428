import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu'];
  
  connect() {
    // this.element.textContent = "Hello World!!"
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }

}

