import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["numberInput"];
  static values = { curr: Number };

  connect() {
    this.currValue = this._sanitizeNumber(this.numberInputTarget.value);
  }

  handlePositiveValue(ev) {
    if (ev.target.value < 0 || ev.target.value === "") {
      ev.target.value = 0;
      ev.target.classList.add("ring-red-500", "text-red-900");
    } else {
      ev.target.classList.remove("ring-red-500", "text-red-900");
    }
  }

  _sanitizeNumber(value) {
    return parseFloat(value.replace(/[.]/g, "").replace(/[,]/g, "."));
  }
}
