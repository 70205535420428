import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import FlatpickrLanguages from "flatpickr/dist/l10n";

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      enableTime: false,
      locale: {
        ...FlatpickrLanguages["de"],
      },
      parseDate(date) {
        if (date.includes('.')) {
          const [day, month, year] = date.split('.');
          return new Date(year, month - 1, day);
        }

        return new Date(date);
      },
      dateFormat: "d.m.Y",
      allowInput: true,
    });
  }
}
