import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['trainingSectionForm', 'trainingSectionFormButton'];

  toggleTrainingSectionForm(event) {
    event.preventDefault();
    this.trainingSectionFormTarget.classList.toggle('hidden');
    this.trainingSectionFormButtonTarget.classList.toggle('hidden');
  }
}
