import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "options", "icon", "output"];

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    Array.from(this.optionsTarget.children).forEach(option => {
      option.addEventListener("mouseenter", () => {
        this.highlightOption(option);
      });

      option.addEventListener("mouseleave", () => {
        this.removeHighlight(option);
      });

      option.addEventListener("click", () => {
        this.selectOption(option);
      });
    });

    this.inputTarget.addEventListener("keypress", event => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  highlightOption(option) {
    option.children[0].classList.add("text-white", "bg-indigo-600");
  }

  removeHighlight(option) {
    option.children[0].classList.remove("text-white", "bg-indigo-600");
    option.children[0].classList.add("text-gray-900");
  }

  selectOption(selectedOption) {
    Array.from(this.optionsTarget.children).forEach(option => {
      if (option === selectedOption) {
        this.highlightSelectedText(option);
        this.showCheckmark(option)
        this.inputTarget.value = option.children[0].dataset.value
      } else {
        option.classList.add("text-gray-900");
        this.removeSelectedTextHighlight(option);
        this.hideCheckmark(option);
      }
    });

    this.hideOptions()
  }

  showCheckmark(option) {
    const checkmark = option.querySelector("#checkmark");
    if (checkmark) {
      checkmark.classList.remove("text-white");
      checkmark.classList.add("text-indigo-600");
    }
  }

  hideCheckmark(option) {
    const checkmark = option.querySelector("#checkmark");
    if (checkmark) {
      checkmark.classList.remove("text-indigo-600");
      checkmark.classList.add("text-white");
    }
  }

  highlightSelectedText(option) {
    const text = option.querySelector("#option-value");
    if (text) {
      text.classList.add("font-semibold");
    }
  }

  removeSelectedTextHighlight(option) {
    const text = option.querySelector("#option-value");
    if (text) {
      text.classList.remove("font-semibold");
    }
  }

  addSelectedValue(){
    const checkmark = this.optionsTarget.querySelector(".text-indigo-600");

    if (checkmark) {
      this.highlightOption(checkmark.parentElement.parentElement)
      this.hideCheckmark(checkmark.parentElement.parentElement);
    }
  }

  toggleOptions() {
    if (this.optionsTarget.classList.contains("hidden")) {
      this.showOptions();
      this.addSelectedValue();
    } else {
      this.hideOptions();
    }
  }

  showOptions() {
    this.optionsTarget.classList.remove("hidden")
    this.optionsTarget.classList.remove("opacity-0", "scale-95")
    this.optionsTarget.classList.add("opacity-100", "scale-100")
  }

  hideOptions() {
    this.optionsTarget.classList.add("opacity-0", "scale-95")
    this.optionsTarget.classList.remove("opacity-100", "scale-100")
    this.optionsTarget.classList.add("hidden")
  }

  hideOptionsOutsideClick(event) {
    if (!this.inputTarget.contains(event.target) && !this.optionsTarget.contains(event.target) && !this.iconTarget.contains(event.target) ) {
      this.hideOptions();
    }
  }

  filterOptions() {
    const inputValue = this.inputTarget.value.toLowerCase();

    Array.from(this.optionsTarget.children).forEach(option => {
      const optionText = option.children[0].dataset.value.toLowerCase();
      if (optionText.includes(inputValue)) {
        option.style.display = "block";
      } else {
        option.style.display = "none";
      }
    });
  }
}
