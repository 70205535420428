import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["nameInput", "bicInput"]

  async ibanChanged(event) {
    const iban = event.target.value;
    const blz = iban.substring(4, 12);

    await get(`/direct/bank_identifiers/search?blz=${blz}`).then(async (response) => {
      if (!response.ok) return;
      const data = await response.json
      const { bank }  = data;

      if (!bank) return;

      this.nameInputTarget.value = bank.name;
      this.bicInputTarget.value = bank.bic;
    })
  }
}
