import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['guaranteedPeriodField']

  connect() {
  }

  toggleguaranteedPeriodFields(e) {
    const selectedOption = e.target.value;

    const selected = selectedOption === 'mit Garantielaufzeit'

    this.guaranteedPeriodFieldTargets.forEach(target => {
      target.classList.toggle('flex', selected);
      target.classList.toggle('hidden', !selected);
    });
  }
}
