import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["linkList", "intersectionSection", "linkedData"];

  static values = {
    intersectionSectionScrollable: Boolean,
    isScrolling: Boolean,
  };

  connect() {
    this.intersectionSectionScrollableValue = false;
    this.setTitleLinks();

    this.initIntersectionObserver();
    this.initResizeObserver(() => {
      this.pauseIntersectionListener(200);
    });

    const firstLinkItem = this.linkListTarget.querySelector("li");
    firstLinkItem.classList.add("text-text-main");

    // TODO: Enable again once it's bug free
    // this.initScrollListener();
    this.initToggleResizeListener();
  }

  disconnect() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  initToggleResizeListener() {
    const toggleContent = document.querySelectorAll(
      '[data-form-toggle-target="content"]'
    );
    toggleContent.forEach((content) => this.resizeObserver.observe(content));
  }

  initResizeObserver(callBack) {
    this.resizeObserver = new ResizeObserver(callBack.bind(this));
  }

  initIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: this.intersectionSectionTarget,
        rootMargin: "0px",
        threshold: 0.45,
      }
    );
  }

  initScrollListener() {
    if (!this.intersectionSectionScrollableValue) {
      this.intersectionSectionTarget.addEventListener("scroll", () => {
        this.intersectionSectionScrollableValue = true;
        this.linkedDataTargets.forEach((target) =>
          this.intersectionObserver.observe(target)
        );
      });
    }
  }

  handleIntersection(entries) {
    if (this.isScrollingValue) return;
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.intersectionSectionScrollableValue) {
        this.handleIntersectionVisibility(entry.target.id);
      }
    });
  }

  pauseIntersectionListener(duration = 750) {
    this.isScrollingValue = true;
    setTimeout(() => {
      this.isScrollingValue = false;
    }, duration);
  }

  handleIntersectionVisibility(targetId) {
    this.linkListTargets.forEach((containerTarget) => {
      containerTarget.querySelectorAll("li").forEach((item) => {
        const isTarget = item.getAttribute("data-link-for") === targetId;
        item.classList.toggle("text-text-main", isTarget);
        // item.classList.toggle("bg-indigo-50", isTarget);
        if (isTarget) {
          location.href = location.href.split("#")[0] + `#${targetId}`;
        }
      });
    });
  }

  setTitleLinks() {
    this.linkListTargets.forEach((target) => this._clearList(target));
    this.linkedDataTargets.forEach((target) => this.createLinkItem(target));
  }

  createLinkItem(target) {
    const targetTitle = target.querySelector("h3");
    let displayName =
      targetTitle.getAttribute("data-display-name") || targetTitle.innerText;
    const targetId = this._generateValidId(displayName);

    target.id = targetId;

    const linkItem = document.createElement("li");
    linkItem.classList.add(
      "py-2",
      "px-3",
      "text-sm",
      "font-medium",
      "text-brand-primary",
      "rounded-md"
    );
    linkItem.setAttribute("data-link-for", targetId);

    const link = document.createElement("a");
    link.href = `#${targetId}`;
    link.innerText = displayName;
    link.setAttribute(
      "data-action",
      "click->intersection#navigateToSection:prevent"
    );

    linkItem.append(link);
    const sectionId = target.dataset.sectionId;

    let containerTarget = this.linkListTarget;

    if (sectionId) {
      containerTarget =
        this.linkListTargets.find(
          (target) => target.dataset.sectionId == sectionId
        ) || this.linkListTarget;
    }
    containerTarget.append(linkItem);
  }

  navigateToSection(ev) {
    this.pauseIntersectionListener();
    const itemSelector = ev.srcElement.href.split("#")[1];
    const currItem = document.querySelector(`#${itemSelector}`);
    currItem.scrollIntoView();
    location.href = ev.srcElement.href;
    this.updateLinkListActiveClass(ev.srcElement.href);
  }

  updateLinkListActiveClass(currentHref) {
    this.linkListTargets.forEach((containerTarget) => {
      containerTarget.querySelectorAll("li").forEach((target) => {
        const itemLink = target.querySelector("a").href;
        target.classList.toggle("text-text-main", itemLink === currentHref);
        // target.classList.toggle("bg-indigo-50", itemLink === currentHref);
      });
    });
  }

  _clearList(listParent) {
    listParent.innerHTML = "";
  }

  _generateValidId(text) {
    return text
      .replace(/[^a-zA-Z0-9]+/g, "_")
      .replace(/^_+|_+$/g, "")
      .toLowerCase();
  }
}
