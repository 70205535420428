import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdownMenu", "toggleButton"];

  connect() {
    const currActions = this.element.dataset.action || "";
    this.forcedPosition = this.element.dataset.forcedPosition;
    this.element.dataset.action =
      `${currActions} click@window->dropdown#overlayClickHandler`.trim();
  }

  overlayClickHandler(ev) {
    const toggler =
      ev.target.tagName === "BUTTON" ? ev.target : ev.target.closest("button");

    // if it doesn't have a dropdown menu all clicks are outside the menu
    const isMenuClick =
      this.hasDropdownMenuTarget && this.dropdownMenuTarget.contains(ev.target);

    // Conditions for hiding
    // no toggler (outside button) and not a menu click or
    // toggler is not the toggling button (in that case we're going to double toggle and immediately close when trying to open)
    if ((!toggler || toggler !== this.toggleButtonTarget) && !isMenuClick)
      this.hideDropdown();
  }

  toggleDropdown() {
    if (this.dropdownMenuTarget.classList.contains("hidden")) {
      this.showDropdown();
    } else {
      this.hideDropdown();
    }
  }

  showDropdown() {
    if (this.forcedPosition) {
      // bug fix for dropdowns inside overflow hidden containers
      const btn = this.element.getBoundingClientRect();
      this.dropdownMenuTarget.style.setProperty("position", "fixed");
      this.dropdownMenuTarget.style.setProperty("top", `${btn.top + btn.height}px`);
      this.dropdownMenuTarget.style.setProperty("right", `${window.innerWidth - btn.right}px`);
    }
    this.dropdownMenuTarget.classList.remove("hidden");
    this.dropdownMenuTarget.classList.remove("opacity-0", "scale-95");
    this.dropdownMenuTarget.classList.add("opacity-100", "scale-100");
  }

  hideDropdown() {
    this.dropdownMenuTarget.classList.add("opacity-0", "scale-95");
    this.dropdownMenuTarget.classList.remove("opacity-100", "scale-100");

    setTimeout(() => {
      this.dropdownMenuTarget.classList.add("hidden");
    }, 75);
  }
}
