import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["time", "button", "resend"];
  static values = { count: String };

  connect() {
    this.countdown();
  }

  countdown() {
    if(this.countValue === "") {
      return;
    }

    const [minutes, seconds] = this.countValue.match(/\d+/g).map(Number);

    if (minutes > 0 || seconds > 0) {
      setTimeout(() => this.decreaseTime(minutes, seconds), 1000);
    } else {
      this.complete();
    }
  }

  updateTime(minutes, seconds) {
    const formattedTimeValue = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    this.timeTarget.innerText = formattedTimeValue;
  }

  decreaseTime(minutes, seconds) {
    if (seconds > 0) {
      seconds -= 1;
    } else if (minutes > 0) {
      minutes -= 1;
      seconds = 59;
    }

    this.updateTime(minutes, seconds);

    if (minutes === 0 && seconds === 0) {
      this.complete();
    } else {
      setTimeout(() => this.decreaseTime(minutes, seconds), 1000);
    }
  }

  complete() {
    this.buttonTarget.classList.remove("invisible");
    this.timeTarget.innerText = "";
    this.resendTarget.innerText = "";
  }

  formatTime(time) {
    return String(time).padStart(2, '0');
  }
}
