import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="money-input"
export default class extends Controller {
  static targets = ["moneyInput", "container"];
  static values = { curr: Number, old: String, allowNil: Boolean };
  connect() {
    if (this.allowNilValue && this.moneyInputTarget.value === "") {
      return
    }
    this.currValue = this.sanitizeNumber(this.moneyInputTarget.value);
    this.formatInputValue();
  }

  sanitizeNumber(value) {
    return parseFloat(value.replace(/[.]/g, "").replace(/[,]/g, "."));
  }

  formatInputValue() {
    if (this.currValue != '') {
      let formattedValue = this.formatNumber(this.currValue);
      this.moneyInputTarget.value = formattedValue;
      this.oldValue = formattedValue
    }
  }

  formatNumber(value) {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  
    return formatter.format(isNaN(value) ? 0 : value);
  }

  format(ev) {
    this.handleInputValue(ev);
  }

  handleInputValue(ev) {
    this.currValue = this.sanitizeNumber(ev.target.value);

    if (this.currValue < 0) {
      this.currValue = 0;
    }

    let formattedValue = this.formatNumber(this.currValue);
    if (formattedValue === "NaN") {
      ev.target.value = this.formatNumber(0);
      ev.target.classList.add("ring-red-500", "text-red-900");
    } else {
      ev.target.value = formattedValue;
      ev.target.classList.remove("ring-red-500", "text-red-900");
    }
  }

  valueChange(_ev) {
    if (!this.validateInput(this.moneyInputTarget.value)) {
      this.moneyInputTarget.value = this.oldValue; 
    }
    this.oldValue = this.moneyInputTarget.value;
  }

  validateInput(value) {
    const regex = /^[0-9]*,?[0-9]*$/;
    return regex.test(value);
  }
}
