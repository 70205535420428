import { Controller } from "@hotwired/stimulus";
import List from "list.js";

export default class extends Controller {
  static targets = ["input", "optionsList", "option", "valueInputField"];
  static values = {
    dataFetched: Boolean,
    dataUrl: String,
    displayProperty: String,
    valueProperty: String,
    eagerLoad: Boolean,
  };

  connect() {
    if (!this.dataUrlValue) return;
    this.list = new List(this.element.id, {
      valueNames: [
        this.displayPropertyValue,
        { data: [this.valuePropertyValue] },
      ],
      item: this.listItemTemplate(),
    });

    this.tryEagerLoad();
    this.setFieldInitialValue();

    this.inputTarget.addEventListener(
      "focusout",
      this.delayedToggleOption.bind(this, "close")
    );
    this.inputTarget.addEventListener(
      "focus",
      this.delayedToggleOption.bind(this, "open")
    );
  }

  disconnect() {
    this.inputTarget.removeEventListener("focusout", this.delayedToggleOption);
    this.inputTarget.removeEventListener("focus", this.delayedToggleOption);
  }

  async tryEagerLoad() {
    if (this.eagerLoadValue) {
      await this.fetchData();
    }
  }

  // Set field value if there is already a selected value (edit mode)
  async setFieldInitialValue() {
    if (this.valueInputFieldTarget.value) {
      await this.fetchData();
      if (this.valuePropertyValue) {
        const currItem = this.items.find(
          (el) =>
            el[this.valuePropertyValue] == this.valueInputFieldTarget.value
        );
        this.inputTarget.value = currItem[this.displayPropertyValue];
      }
    }
  }

  // we delay toggling the display state so that we can get and update the value first.
  delayedToggleOption(action) {
    setTimeout(() => {
      this.toggleOptionsDisplayState(action);
    }, 200);
  }

  async fetchData() {
    if (this.dataFetchedValue || !this.dataUrlValue) return;
    const response = await fetch(this.dataUrlValue);
    const json = await response.json();
    this.items = json;
    this.displayItems = this.items.map((el) => el[this.displayPropertyValue]);
    this.list.add(json);
    this.dataFetchedValue = true;
    return json;
  }

  chooseOption(ev) {
    const selectedName = ev.target.innerText;
    this.inputTarget.value = selectedName;
    this.currSelectedValue = selectedName;
    this.toggleOptionsDisplayState("close");
    this.dispatch('optionSelected')
  }

  toggleOptionsDisplayState(action = "open") {
    if (action === "open") this.list.fuzzySearch(this.inputTarget.value);
    this.setValidatedInputValue();
    const isOpening = action === "open" ? true : false;
    this.optionsListTarget.classList.toggle("flex", isOpening);
    this.optionsListTarget.classList.toggle("reveal_options", isOpening);
    this.optionsListTarget.classList.toggle("hidden", !isOpening);
  }

  setValidatedInputValue() {
    if (!this.displayItems?.includes(this.inputTarget.value)) {
      this.inputTarget.value = "";
      this.currSelectedValue = "";
      this.valueInputFieldTarget.value = "";
    } else {
      if (this.valuePropertyValue) {
        const currItem = this.items.find(
          (el) => el[this.displayPropertyValue] === this.inputTarget.value
        );
        this.valueInputFieldTarget.value = currItem[this.valuePropertyValue];
      } else this.valueInputFieldTarget.value = this.inputTarget.value;
    }
  }

  listItemTemplate() {
    return `   
    <li class="lg:min-w-[200px] px-2 py-4 hover:bg-gray-100  rounded-md"  data-action="click->searchable-input#chooseOption" data-searchable-input-target="option">
          <p class="${this.displayPropertyValue}"></p>
    </li>`;
  }
}
