// app/javascript/controllers/toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    paymentTypeDefault: String,
    durationDefault: String
  }

  static targets = ["eventBus", "typeCheckbox", "paymentType", "duration"]

  connect() {
    // Without eventBusTarget, it doesn't make sense. So we should crash.
    if(this.hasEventBusTarget) {
      this.eventBusTarget.addEventListener("change", (ev) => {
        this.toggle(ev.detail.checked)
      });
    }
  }

  toggle(onOrOff) {
    if (onOrOff) {
      this.setDefaults()
    } else {
      this.clearValues()
    }
    this.dispatch("financing-toggled", { detail: { on: onOrOff } })
  }
  setDefaults() {
    this.paymentTypeTargets.forEach((target) => {
      let radio = target.querySelector('input[type="radio"]')
      if (radio.value === this.paymentTypeDefaultValue) {
        target.click()
      }
    })
  
    this.durationTarget.querySelectorAll('option').forEach((option) => {
      if (option.value === this.durationDefaultValue) {
        option.selected = true
      }
    })
  }

  clearValues() {
    this.paymentTypeTargets.forEach((target) => {
      let radio = target.querySelector('input[type="radio"]')
      radio.checked = false
    })
    this.durationTarget.selectedIndex = 0 // Reset to the "Choose field" option
  }

  // This is added in this controller becase it is connected with finaning and installments and we don't have any other controller for policy parameter.
  async setMinimumOneTimePayment(event) {
    event.preventDefault();

    const url = event.currentTarget.href;
    const saveButton = document.querySelector('[data-action="click->global-save-button#submitAllLocalForms"]');

    const observer = new MutationObserver((mutations, observer) => {
      const errorElement = document.querySelector('#record_errors');
      if (errorElement) {
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    try {
      saveButton.click();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const errorElement = document.querySelector('#record_errors');

      // If saving the form will renders any error then don't call the next action
      if (errorElement) {
        return;
      }

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Accept": "text/vnd.turbo-stream.html",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content || ''
        }
      });

      const html = await response.text();
      Turbo.renderStreamMessage(html);
    } finally {
      observer.disconnect();
    }
  }
}
