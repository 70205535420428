import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["pdfList", "preview", "noDocumentMessage"];
  pdfs;

  static values = {
    customerId: Number
  };

  async connect() {
    await this.getPdfs();
  }

  togleAllPdf() {
    const checkboxs = this.pdfListTarget.querySelectorAll(
      "input[type=checkbox]"
    );
    checkboxs.forEach((checkBox) => (checkBox.checked = true));
  }

  async getPdfs() {
    const customerId = this.customerIdValue
    const res = await fetch(`/agents/customers/${customerId}/fetch_documents`);
    const { pdfs } = await res.json();
    this.pdfs = pdfs;
    this.pdfListTarget.innerHTML = "";
    pdfs.forEach((file, index) => {
      const fileName = this._sanitizeFileName(file.filename);
      const listItem = document.createElement("li");
      listItem.classList.add(
        "border-y",
        "pl-2",
        "py-4",
        "flex",
        "gap-2.5",
        "justify-between"
      );
      const checkContainer = document.createElement("div");
      checkContainer.classList.add("flex", "gap-4", "px-6", "items-center");
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = file.filename.split(".")[0];
      checkbox.name = fileName;
      checkbox.classList.add(
        "rounded",
        "border-gray-300",
        "h-4",
        "w-4",
        "text-brand-primary",
        "focus:ring-brand-primary"
      );
      const label = document.createElement("label");
      label.classList.add(
        "block",
        "text-sm",
        "font-medium",
        "leading-6",
        "lg:basis-[200px]",
        "text-text-main",
        "lg:flex-grow-0"
      );
      label.innerText = fileName;
      label.setAttribute("for", file.filename.split(".")[0]);
      checkContainer.appendChild(checkbox);
      checkContainer.appendChild(label);
      listItem.appendChild(checkContainer);
      const tagContainer = document.createElement("div");
      tagContainer.classList.add(
        "flex",
        "items-center",
        "justify-center",
        "px-6"
      );
      const tag = document.createElement("button");
      tag.type = "button";
      tag.setAttribute("data-action", "click->pdf-download#previewPdf");
      tag.setAttribute("data-pdf-index", index);
      tag.classList.add(
        "py-0.5",
        "px-2.5",
        "bg-blue-100",
        "text-brand-primary",
        "text-xs",
        "font-medium",
        "rounded-full"
      );
      tag.innerText = "PDF";
      tagContainer.appendChild(tag);
      listItem.appendChild(tagContainer);
      this.pdfListTarget.appendChild(listItem);
    });
  }

  previewPdf(ev) {
    this.noDocumentMessageTarget.classList.add("hidden");
    const pdfIndex = ev.srcElement.dataset.pdfIndex;
    this.previewTarget.src = `data:application/pdf;base64,${this.pdfs[pdfIndex].content}`;
  }

  _sanitizeFileName(filename) {
    let sanitized = filename.split(".")[0];
    sanitized = sanitized.replaceAll(/[_-]/g, " ");

    return sanitized;
  }
}
