import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "insuranceCompanyField",
    "insuranceProductField",
  ];

  static values = {
    productId: Number,
  };

  async updateValuesOnLoad() {
    try {
      const insuranceCompanyId = this.insuranceCompanyFieldTarget.value;
      const productId = this.productIdValue;

      if (insuranceCompanyId) {
        const response = await fetch(`/agents/customers/products_data?insurance_company_id=${insuranceCompanyId}`);
        const data = await response.json();
        this.updateSelectOptions(this.insuranceProductFieldTarget, data, productId);
      }
    } catch (err) {}
  }

  async getInsuranceProducts(event) {
    const insuranceCompanyId = event.target.value;

    if (insuranceCompanyId) {
      const response = await fetch(`/agents/customers/products_data?insurance_company_id=${insuranceCompanyId}`);
      const data = await response.json();
      this.updateSelectOptions(this.insuranceProductFieldTarget, data);
    } else {
      this.clearSelectOptions(this.insuranceProductFieldTarget);
    }
  }

  updateSelectOptions(selectElement, data, selectedValue = null) {
    this.clearSelectOptions(selectElement);
    data.forEach(option => {
      const optionElement = document.createElement("option");
      optionElement.value = option.value;
      optionElement.text = option.text;
      if (selectedValue !== null && option.value === selectedValue) {
        optionElement.selected = true;
      }
      selectElement.add(optionElement);
    });
  }

  clearSelectOptions(selectElement) {
    while (selectElement.options.length > 1) {
      selectElement.remove(1);
    }
  }
}
