import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    policyApplicationId: Number,
  }

  connect() {
  }

  async addIndividualFund(ev) {
    const fundId = ev.srcElement.value
    const url =  `/agents/policy_applications/${this.policyApplicationIdValue}/step5/add_individual_fund`
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content || ""
      },
      body: JSON.stringify({ fund_id: fundId })
    })
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  async updateIndividualFund(ev) {
    const portfolioFundsDetailId = ev.srcElement.dataset["portfolioFundsDetailId"] 
    const fundId = ev.srcElement.value
    const url =  `/agents/policy_applications/${this.policyApplicationIdValue}/step5/update_individual_fund/${portfolioFundsDetailId}`
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content || ""
      },
      body: JSON.stringify({fund_id: fundId})
    })
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }
}
