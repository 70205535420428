import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'calculatorField',
    'reducedMonthlyContributionDurationField',
    'paymentTypeField',
    'paymentDurationField',
    'financingReductionAmountField',
    'repaymentFinancingViaReductionField'
  ];

  static values = {
    reducedMonthlyContributionDuration: Number,
    paymentType: String,
    paymentDuration: Number,
    financingReductionAmount: Number,
    repaymentFinancingViaReduction: Boolean
  };

  connect() {
  console.log("Load step 3")
    this.timer = null;
    // This is a quick fix for now. Will find another solution
    document.addEventListener("turbo:load", () => {
      this.updateValuesOnLoad();
    });
  }

  async handleInputChange() {
    this.clearTimeoutAndCalculateResult();
  }

  async handleBlur() {
    this.clearTimeoutAndCalculateResult();
  }

  async clearTimeoutAndCalculateResult() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.calculateResult();
    }, 1000);
  }

  async calculateResult() {
    const fieldsData = this.getFieldsData();

    if (Object.keys(fieldsData).length === this.calculatorFieldTargets.length) {
      await this.fetchAndRender(`/agents/policy_applications/calculator_results?data=${JSON.stringify(fieldsData)}`);
    }
  }

  getFieldsData() {
    const fieldsData = {};
    this.calculatorFieldTargets.forEach(field => this.addField(field, fieldsData));
    return fieldsData;
  }

  addField(field, fieldsData) {
    const key = this.extractKeyFromFieldName(field.name);

    if (field.type == 'checkbox') {
      fieldsData[key] = field.checked;
    } else if (field.value) {
      fieldsData[key] = field.value;
    }
  }

  extractKeyFromFieldName(fieldName) {
    return fieldName.match(/\[([^\]]+)\]$/)[1];
  }

  async fetchAndRender(url) {
    const response = await fetch(url);
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  handlePolicyStartDate(e) {
    const inputDate = e.target.value;
    const [day, month, year] = inputDate.split('.').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const nextMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    e.target.classList.toggle("ring-red-500", "text-red-900", selectedDate < nextMonthStartDate);
    e.target.blur();
  }

  toggleReducedMonthlyContributionDuration() {
    const checkbox = document.getElementById("comparison_calculator_reduced_monthly_contribution_duration_toggle");
    if (checkbox.checked) {
      this.reducedMonthlyContributionDurationFieldTarget.value = this.reducedMonthlyContributionDurationValue;
    }
    this.toggleCalculatorFieldTarget(this.reducedMonthlyContributionDurationFieldTarget, checkbox);
    this.handleInputChange();
  }

  togglePaymentType() {
    const checkbox = document.getElementById("comparison_calculator_payment_type_toggle");
    const isChecked = checkbox.checked;
    if (isChecked) {
      this.selectPaymentTypeOption();
      this.setPaymentFieldsValues();
    } else {
      this.paymentTypeFieldTargets.forEach(target => this.toggleCalculatorFieldTargetForRadioField(target, checkbox));
    }
    [
      this.paymentDurationFieldTarget,
      this.financingReductionAmountFieldTarget,
      this.repaymentFinancingViaReductionFieldTarget,
    ].forEach(field => this.toggleCalculatorFieldTarget(field, checkbox));
    this.handleInputChange();
  }

  togglePaymentTypeField() {
    this.paymentTypeFieldTargets.forEach(target => this.toggleCalculatorFieldTargetForRadioField(target, target.querySelector('input[type="radio"]')));
    this.handleInputChange();
  }

  async selectPaymentTypeOption() {
    const radioField = this.paymentTypeFieldTargets.find(target => target.querySelector('input[type="radio"]').value === this.paymentTypeValue);
    if (radioField) radioField.click();
  }

  async setPaymentFieldsValues() {
    this.paymentDurationFieldTarget.value = this.paymentDurationValue;
    this.financingReductionAmountFieldTarget.value = parseFloat(this.financingReductionAmountValue);
    this.financingReductionAmountFieldTarget.dispatchEvent(new Event("blur"));
    this.repaymentFinancingViaReductionFieldTarget.checked = this.repaymentFinancingViaReductionValue;
  }

  async toggleCalculatorFieldTarget(target, checkbox) {
    let existingTargets = target.getAttribute('data-offers--step-three-target');
    if (checkbox.checked) {
      const newTargets = existingTargets ? `${existingTargets} calculatorField` : 'calculatorField';
      target.setAttribute('data-offers--step-three-target', newTargets);
    } else {
      if (existingTargets) {
        const updatedTargets = existingTargets.replace('calculatorField', '').trim();
        target.setAttribute('data-offers--step-three-target', updatedTargets || null);
      }
    }
  }

  toggleCalculatorFieldTargetForRadioField(target, checkbox) {
    const radio = target.querySelector('input[type="radio"]');
    let existingTargets = radio.getAttribute("data-offers--step-three-target");
    if (checkbox.checked) {
      if (!existingTargets || !existingTargets.includes("calculatorField")) {
        const newTargets = existingTargets ? `${existingTargets} calculatorField` : "calculatorField";
        radio.setAttribute("data-offers--step-three-target", newTargets);
      }
    } else {
      if (existingTargets) {
        const updatedTargets = existingTargets.replace("calculatorField", "").trim();
        radio.setAttribute("data-offers--step-three-target", updatedTargets || null);
      }
    }
  }

  async updateValuesOnLoad() {
    try {
      const monthly_contribution_duration_checkbox = document.getElementById("comparison_calculator_reduced_monthly_contribution_duration_toggle");
      const payment_type_checkbox = document.getElementById("comparison_calculator_payment_type_toggle");
      if (monthly_contribution_duration_checkbox.checked) {
        this.reducedMonthlyContributionDurationFieldTarget.value = this.reducedMonthlyContributionDurationValue;
        await this.toggleCalculatorFieldTarget(this.reducedMonthlyContributionDurationFieldTarget, monthly_contribution_duration_checkbox);
      }
      if (payment_type_checkbox.checked) {
        await this.setPaymentFieldsValues();
        [
          this.paymentDurationFieldTarget,
          this.financingReductionAmountFieldTarget,
          this.repaymentFinancingViaReductionFieldTarget,
        ].forEach(field => this.toggleCalculatorFieldTarget(field, payment_type_checkbox));
        await this.selectPaymentTypeOption();
      } else {
        await this.calculateResult();
      }
    } catch (err) {}
  }
}
