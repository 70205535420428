import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["label", "checkbox"]
  static values = { nettoweltConfigurationId: Number }

  navigate(event) {
    // Prevent default click event
    event.preventDefault()

    // Check if the click originated from a checkbox
    if (event.target.matches('input[type="checkbox"]')) {
      return
    }

    // Get the insurance company id from the label
    const insuranceCompanyId = event.currentTarget.dataset.companyId
    const nettoweltConfigurationId = this.nettoweltConfigurationIdValue

    // Send a GET request to the desired path
    get(`/direct/company_configurations/select?insurance_company_id=${insuranceCompanyId}&nettowelt_configuration_id=${nettoweltConfigurationId}`, { responseKind: "turbo-stream" })
  }

  navigateToStandard(event) {
    // Prevent default click event
    event.preventDefault()

    const nettoweltConfigurationId = this.nettoweltConfigurationIdValue

    get(`/direct/nettowelt_configurations/${nettoweltConfigurationId}`, { responseKind: "turbo-stream" })
  }

  toggle(event) {
    // Prevent the label click event from firing
    event.stopPropagation()

    // Get the insurance company id from the checkbox
    const insuranceCompanyId = event.target.value
    const nettoweltConfigurationId = this.nettoweltConfigurationIdValue

    fetch(`/direct/company_configurations/toggle`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ insurance_company_id: insuranceCompanyId, nettowelt_configuration_id: nettoweltConfigurationId })
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
