import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "select"];

  static values = {
    options: String
  }

  connect() {
    this.addEventListener();
  }

  addEventListener() {
    this.inputTarget.addEventListener("keypress", event => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  filterOptions() {
    const inputValue = this.inputTarget.value.toLowerCase();

    const options = document.querySelector(`#${this.optionsValue}`);

    Array.from(options.children).forEach(option => {
      const optionText = option.dataset.value.toLowerCase();
      if (optionText.includes(inputValue)) {
        option.classList.remove('hidden');
      } else {
        option.classList.add('hidden');
      }
    });
  }

  selectCheckBox() {
    const options = document.querySelector(`#${this.optionsValue}`);
    const checkboxes = options.querySelectorAll('input[type="checkbox"]');

    const selectAll = this.selectTarget.checked;

    checkboxes.forEach(checkbox => {
      if (!checkbox.closest('.hidden')) {
        checkbox.checked = selectAll;
      }
    });
  }

  saveValue() {
    this.inputTarget.value = this.inputTarget.value
  }
}
