import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ['products', 'fundForm', 'fundContainer']
  connect() {
    this.fundFormClone = this.fundFormTarget.cloneNode(true)
    this.deleteIndex = 1000 // padding for items to be deleted
    // this.updateForms()
  }

  async productsChanged() {
    const products = Array.from(this.productsTarget.selectedOptions).map(option => option.value)
    const portfolioId = this.element.dataset.portfolioId

    await post(`/agents/portfolios/individual_fund_list`, {
      body: JSON.stringify({ portfolio_id: portfolioId, insurance_products: products }),
      responseKind: "turbo-stream"
    })

    setTimeout(() => {
      this.fundFormClone = this.fundFormTarget.cloneNode(true)
    }, 50)
  }

  newFundSelected(event) {
    const fundForm = event.target.closest('.fund-form')
    if (fundForm.classList.contains('existing-fund')) {
      return
    }

    fundForm.querySelector('.delete-fund').classList.remove('hidden')
    fundForm.querySelector('.allocation').classList.remove('hidden')
    fundForm.classList.add('existing-fund')

    const newForm = this.fundFormClone.cloneNode(true)
    this.element.querySelectorAll('.existing-fund .fund-select').forEach(select => {
      newForm.querySelector(`option[value="${select.value}"]`)?.remove()
    })

    this.fundContainerTarget.appendChild(newForm)
    this.updateForms()
  }

  fundRemoved(event) {
    event.preventDefault()

    const fundForm = event.target.closest('.fund-form')
    const newForm = this.fundFormClone.cloneNode(true)
    this.element.querySelectorAll('.existing-fund .fund-select').forEach(select => {
      newForm.querySelector(`option[value="${select.value}"]`)?.remove()
    })

    const newFundForm = this.element.querySelector('.fund-form:not(.existing-fund)')
    newFundForm.remove()

    const id = fundForm.dataset.id
    if (id) {
      this.deleteIndex += 1
      fundForm.insertAdjacentHTML('afterend', `<input type="hidden" name="portfolio[portfolio_funds_details_attributes][${this.deleteIndex}][_destroy]" value="1">`)
      fundForm.insertAdjacentHTML('afterend', `<input type="hidden" name="portfolio[portfolio_funds_details_attributes][${this.deleteIndex}][id]" value="${id}">`)
    }
    fundForm.remove()

    this.fundContainerTarget.appendChild(newForm)
    this.updateForms()
  }

  fundUpdated(event) {}

  updateForms() {
    const fundsCount = this.element.querySelectorAll('.existing-fund').length
    const percentage = Math.floor(100 / fundsCount)

    this.element.querySelectorAll('.fund-index').forEach((el, index) => {
      el.innerHTML = `Fond ${index + 1}`
    })

    this.element.querySelectorAll('.existing-fund').forEach((form, index) => {
      const finalPercentage = index === fundsCount - 1 ? (100 - percentage * index) : percentage
      const allocationInput = form.querySelector('[data-id="allocation"]')
      allocationInput.value = finalPercentage.toFixed(2).replace('.', ',')
      const normalizedValue = form.querySelector('[data-percentage-input-target="normalizedValue"]')
      normalizedValue.setAttribute('name', `portfolio[portfolio_funds_details_attributes][${index}][allocation]`)
      normalizedValue.value = finalPercentage/100
      form.querySelector('.fund-select').setAttribute('name', `portfolio[portfolio_funds_details_attributes][${index}][fund_id]`)
      form.querySelector('.id').setAttribute('name', `portfolio[portfolio_funds_details_attributes][${index}][id]`)
    })
  }
}
