import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'content', 'container'];
  static values = { open: Boolean };

  connect() {
    if (!this.openValue) {
      this.contentTarget.classList.toggle('hidden', !this.openValue);
    }
  }

  toggle(){
    this.openValue = !this.openValue;
    const iconPath = this.openValue ? 'M5 15L12 8L19 15' : 'M19 9L12 16L5 9';
    this.iconTarget.querySelector('path').setAttribute('d', iconPath);
    this.contentTarget.classList.toggle('flex', this.openValue);
    this.contentTarget.classList.toggle('hidden', !this.openValue);
    this.containerTarget.classList.toggle('border-b', this.openValue);
    this.containerTarget.classList.toggle('pb-8', this.openValue);
  }
}
