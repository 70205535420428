import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "saveButton"];
  timeoutId = null;

  connect() {
    console.log(
      "connected _validate_sections_controller_ with ",
      this.itemTargets.length,
      " items. And save button: ",
      this.hasSaveButtonTarget
    );
    this.checkStatus();

    addEventListener("turbo:before-stream-render", (event) => {
      const fallbackToDefaultActions = event.detail.render;

      event.detail.render = (streamElement) => {
        if (
          ["update", "replace"].includes(streamElement.action)
        ) {
          this.scheduleCheckStatus();
        }
        fallbackToDefaultActions(streamElement);
      };
    });
  }

  scheduleCheckStatus() {
    // Clear the existing timeout if there's another Turbo render call
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Delay the checking a bit - just to make sure all other turbo frames got rendered now.
    this.timeoutId = setTimeout(() => {
      this.checkStatus();
    }, 30);
  }

  checkStatus() {
    if (!this.hasSaveButtonTarget){ return; }
    // console.log("....checkStatus with ", this.itemTargets.length, " items")

    // this.itemTargets.every((item, index) => {
    //     const span = item.querySelector("span");
    //     const hasGreenClass = span.classList.contains("bg-green-400");

    //     // Debugging information
    //     console.log(`Item ${index + 1}:`);
    //     console.log("Item element:", item);
    //     console.log("Span element:", span);
    //     console.log("Has green class:", hasGreenClass);

    //   });

    const noneRed = this.itemTargets.every((item) =>
      !item.querySelector("span").classList.contains("bg-red-400")
    );
    if (noneRed) {
      this.saveButtonTarget.classList.remove("hidden");
    } else {
      this.saveButtonTarget.classList.add("hidden");
    }
  }

  // This function will be called when a turbo frame is replaced
  frameUpdated() {
    this.checkStatus();
  }
}
