// app/javascript/controllers/turbo_toggle_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "confirmUncheck",
    "content",
    "checkbox",
    "displayedToggle",
  ];

  connect() {
    this.toggleStyles();
    this.toggleContent();

    console.log("Turbo Toggle controller connected");
  }

  // Till: chatgpt wrote this. It seems to work, but it's complex.
  // TODO: Also: the dialog is not standard. I should show the dialog we use throughout the app
  toggle(event) {
    // In case the user just toggles on + off, don't show the alert
    const formAllBlank = Array.from(
      this.contentTarget.querySelectorAll(
        'input:not([type="hidden"]):not([type="submit"]):not([type="checkbox"])'
      )
    ).every((input) => input.value.trim() === "");

    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    this.toggleStyles();
    this.toggleContent();

    // Check if the checkbox is being unchecked and the confirmUncheck target is present
    if (
      !formAllBlank &&
      this.hasConfirmUncheckTarget &&
      !event.target.checked
    ) {
      event.preventDefault(); // Prevent the checkbox state from changing immediately

      // Get the confirmation message from the target or use a default
      const confirmationMessage =
        this.confirmUncheckTarget.textContent || "Do you confirm?";

      // Use setTimeout to delay the confirmation dialog to after the event cycle
      setTimeout(() => {
        if (confirm(confirmationMessage)) {
          // Manually toggle the checkbox to reflect the user's confirmation
          event.target.checked = false; // Uncheck manually since user confirmed
          this.submitForm(); // Submit the form after confirmation
        } else {
          // Recheck the checkbox since the user did not confirm
          event.target.checked = true;
        }
      }, 0);
    } else {
      // If checking the box, just submit the form
      this.submitForm();
    }
  }

  toggleStyles() {
    const isVisible = this.checkboxTarget.checked;
    const displayedChild = this.displayedToggleTarget.querySelector("span");
    if (this.checkboxTarget.disabled) {
      this.displayedToggleTarget.classList.remove("bg-gray-200");
      this.displayedToggleTarget.classList.add("bg-[#B3BECA]");
    } else {
      this.displayedToggleTarget.classList.toggle(
        "bg-brand-primary",
        isVisible
      );
      this.displayedToggleTarget.classList.toggle("bg-gray-200", !isVisible);
    }
    displayedChild.classList.toggle("translate-x-5", isVisible);
    displayedChild.classList.toggle("translate-x-0", !isVisible);
  }

  toggleContent() {
    try {
      const isVisible = this.checkboxTarget.checked;

      this.contentTarget.classList.toggle("flex", isVisible);
      this.contentTarget.classList.toggle("hidden", !isVisible);
    } catch (error) {
      return;
    }
  }

  submitForm() {
    console.log("Turbo Toggle controller submitted");
    this.formTarget.requestSubmit();
  }
}
