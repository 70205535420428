console.log('Vite ⚡️ Rails')

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
import "trix"
import "@rails/actiontext"

import {Turbo} from "@hotwired/turbo-rails"
document.addEventListener('turbo:load', ()=>{
	console.log("Turbo loaded!")
})

import '../controllers'

import 'chartkick/chart.js'

// https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
Turbo.StreamActions.redirect = function() {
	let url = this.getAttribute("url")
	let parsedUrl = new URL(url);

	if (parsedUrl.origin === window.location.origin || isExternalUrlAllowed(parsedUrl)) {
		Turbo.visit(url);
	} else {
		console.log("Redirection to this URL is not allowed.");
	}
}

Turbo.StreamActions.scroll_to = function () {
  const target = this.targetElements[0];
  target.scrollIntoView({behavior: "smooth"});
};

function isExternalUrlAllowed(url) {
  const allowedHosts = ['www.nettowelt.info'];

  return allowedHosts.includes(url.hostname);
}

// https://github.com/RoleModel/turbo-confirm
import TC from "@rolemodel/turbo-confirm"

TC.start()
