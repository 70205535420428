import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select', 'selectAll']

  toggleSelected() {
    const selectAll = this.selectAllTarget.checked
    this.selectTargets.forEach(select => {
      select.checked = selectAll
    })
  }

  toggleSelectAll() {
    const allSelected = this.selectTargets.every(select => select.checked)
    this.selectAllTarget.checked = allSelected
  }
}
