import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "submitButton"];
  static values = {
    hideSaveButton: Boolean,
    isNew: Boolean,
  };

  connect() {
    // console.log("Form Change Monitor with ", this.fieldTargets.length, " targets.")
    // console.log(JSON.stringify(this.getFormValues()))
    this.formId = this.element.querySelector("form")?.id;
    this.formKey = `${this.formId}_values`;

    this.dispatch('connected')
    this.originalValues = this.getFormValues();
    this.maybeEnableSubmitButton();
  }

  onFieldChange() {
    this.maybeEnableSubmitButton();
  }

  onSubmit(event) {
    event.preventDefault();
    this.saveForm();
  }

  onReloadRequest(event) {
    window[this.formKey] = this.originalValues;
    this.saveForm(true);
  }

  saveForm(isReload = false) {
    const form = this.element.querySelector("form");
    if (isReload) {
      const hiddenField = document.createElement("input");
      Object.assign(hiddenField, {
        type: "hidden",
        name: "turbo_reload",
        value: 1,
      });
      form.appendChild(hiddenField);
    }

    form.requestSubmit();
    if (!isReload) this.originalValues = this.getFormValues();
    this.dispatchFormModifiedStatus(isReload);
  }

  maybeEnableSubmitButton() {
    const currentValues = this.getFormValues();
    const isModified =
      JSON.stringify(currentValues) !== JSON.stringify(this.originalValues);
    if (this.submitButtonTarget.dataset.overridden !== "true" && this.submitButtonTarget.dataset.alwaysHidden !== "true") {
      this.submitButtonTarget.classList.toggle("hidden", !isModified);
    }
    this.submitButtonTarget.dataset.shouldSave = isModified;
    this.dispatchFormModifiedStatus(isModified);
  }

  dispatchFormModifiedStatus(isModified) {
    this.dispatch("formDirtied", { detail: { isModified }, bubbles: true });
  }

  getFormValues() {
    if (window[this.formKey]) {
      const persistedValues = window[this.formKey];
      delete window[this.formKey];

      return persistedValues;
    }

    return this.fieldTargets.reduce((values, field) => {
      values[field.name] =
        field.type === "checkbox" ? field.checked : field.value;
      return values;
    }, {});
  }
}
