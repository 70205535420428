import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editable-field"
export default class extends Controller {
  static targets = ["inputField"];

  disabledClasses = ["border-none", "pointer-events-none", "p-0"];

  connect() {
    this._adjustInputSize(20);
  }

  makeEditable(e) {
    this.inputFieldTarget.classList.remove(...this.disabledClasses);
    this.inputFieldTarget.focus();
    this.inputFieldTarget.addEventListener(
      "keypress",
      this.handleKeyPress.bind(this)
    );
    this.inputFieldTarget.addEventListener("blur", this.handleBlur.bind(this));
    this.inputFieldTarget.addEventListener(
      "input",
      this.handleInput.bind(this)
    );
  }

  handleKeyPress(event) {
    if (event.key === "Enter" || event.key === "Escape") {
      this.inputFieldTarget.dispatchEvent(new Event("blur"));
    }
  }

  handleBlur() {
    this.inputFieldTarget.classList.add(...this.disabledClasses);
    this._adjustInputSize();
  }
  handleInput() {
    this._adjustInputSize();
  }

  _adjustInputSize(offset = 10) {
    const input = this.inputFieldTarget;
    const inputValue = input.value;
    const tempInput = document.createElement("span");
    tempInput.textContent = inputValue;
    tempInput.style.visibility = "hidden";
    tempInput.style.whiteSpace = "pre";
    document.body.appendChild(tempInput);
    const inputWidth = tempInput.offsetWidth;
    document.body.removeChild(tempInput);
    input.style.width = `${inputWidth + offset}px`;
  }
}
