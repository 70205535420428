import { Modal as CustomModal } from "tailwindcss-stimulus-components";

export default class Modal extends CustomModal {
  static targets = ["form"]
  connect() {
    super.connect();

    if (this.element.dataset.preventClose) {
      this.preventClose()
    }
  }

  preventClose() {
    this.dialogTarget.addEventListener("cancel", (event) => {
      event.preventDefault();
    })
  }

  handleResponse({ detail: { success } }) {
    if (success) {
      this.formTarget.reset()
      this.close()
    }
  }
}
