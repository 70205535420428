import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categoryList", "itemList"]
  static values = {
    options: String,
    selectedItem: Number,
    selectedItems: Array,
    placeholder: String,
    multiple: Boolean
  }

  connect() {
    this.options = JSON.parse(this.optionsValue)
    if (this.multipleValue) {
      let categories = this.findCategoriesFromSelectedItems()
      this.updateCategoryOptions(categories)
      this.updateItemOptions(categories)
    } else {
      let category = this.findCategoryFromSelectedItem()
      this.updateCategoryOptions(category)
      this.updateItemOptions(category)
    }
  }

  // trigger from action
  chooseCategory(event) {
    if (this.multipleValue) {
      const selectedCategories = Array.from(event.target.selectedOptions).map(option => parseInt(option.value, 10))
      this.updateItemOptions(selectedCategories)
    } else {
      const selectedCategoryId = parseInt(event.target.value, 10)
      this.updateItemOptions(selectedCategoryId)
    }
  }

  findCategoryFromSelectedItem() {
    const selectedItem = this.selectedItemValue
    for (let category of this.options) {
      if (category.options.some(option => option.id === selectedItem)) {
        this.categoryListTarget.value = category.id
        return category.id
      }
    }
    return null
  }

  findCategoriesFromSelectedItems() {
    const selectedItems = this.selectedItemsValue
    let categories = []
    for (let category of this.options) {
      if (category.options.some(option => selectedItems.includes(option.id))) {
        categories.push(category.id)
      }
    }
    return categories
  }

  updateCategoryOptions(selectedCategoryIds) {
    // convert to list if it's not
    if (!Array.isArray(selectedCategoryIds)) selectedCategoryIds = [selectedCategoryIds];

    const categoryList = this.categoryListTarget
    categoryList.innerHTML = ""

    if (this.placeholderValue) this.addPlaceholder(categoryList);

    this.options.forEach(category => {
      const option = document.createElement("option")
      option.value = category.id
      option.textContent = category.name
      if (selectedCategoryIds.includes(category.id)) {
        option.selected = true
      }
      categoryList.appendChild(option)
    })
  }

  updateItemOptions(categoryIds) {
    // convert to list if it's not
    if (!Array.isArray(categoryIds)) categoryIds = [categoryIds];
    const selectedCategories = this.options.filter(option => categoryIds.includes(option.id))

    this.clearItemOptions()
    if (selectedCategories.length === 0) return;

    selectedCategories.forEach(selectedCategory => {
      selectedCategory.options.forEach(item => {
        const option = document.createElement("option")
        option.value = item.id
        option.textContent = item.name
        if (item.id === this.selectedItemValue || this.selectedItemsValue?.includes(item.id)) {
          option.selected = true
        }
        this.itemListTarget.appendChild(option)
      })
    })

    this.dispatch('items-updated')
  }

  clearItemOptions() {
    const itemList = this.itemListTarget
    itemList.innerHTML = ""

    const isMultiple = itemList.attributes['multiple'] !== undefined
    if (!this.placeholderValue|| isMultiple) return;

    const option = document.createElement("option")
    option.value = ""
    option.textContent = this.placeholderValue
    option.disabled = true
    option.selected = true
    itemList.appendChild(option)
  }

  addPlaceholder(selectElement) {
    const option = document.createElement("option")
    option.value = ""
    option.textContent = this.placeholderValue
    option.disabled = true
    option.selected = true
    selectElement.appendChild(option)
  }
}
