import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-selection"
export default class extends Controller {
  static targets = ["checkBox"];

  toggleAllCheckBox () {
    const isSomeUncheckedCheckBox = this.checkBoxTargets.some((checkBox) => !checkBox.checked);

    this.checkBoxTargets.forEach((checkBox) => (checkBox.checked = isSomeUncheckedCheckBox));
  }

  getSelectedCheckBoxIds() {
    return this.checkBoxTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
  }
}
