import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetId: String
  }

  toggle() {
    const accordionRowId = `accordion_row_${this.targetIdValue}`;
    const targetElement = document.getElementById(accordionRowId);
    if (targetElement.classList.contains("hidden")) {
      this.showOptions(targetElement);
    } else {
      this.hideOptions(targetElement);
    }
  }

  showOptions(targetElement) {
    targetElement.classList.add("transition-opacity", "ease-out", "duration-300");
    targetElement.classList.remove("hidden");
    setTimeout(() => {
      targetElement.classList.remove("opacity-0");
      targetElement.classList.add("opacity-100");
    }, 10);
  }

  hideOptions(targetElement) {
    targetElement.classList.add("transition-opacity", "ease-in", "duration-300");
    targetElement.classList.remove("opacity-100");
    targetElement.classList.add("opacity-0");
    setTimeout(() => {
      targetElement.classList.add("hidden");
    }, 300);
  }
}
